.w-dropdown-link .logo-oaknut,
.w-dropdown-link .logo-oaktree,
.w-dropdown-link .logo-oakwood {
  margin-left: 0;
  margin-bottom: 0;
}

.w-dropdown-link .logo-oaknut {
  margin-top: 6px;
}

.w-dropdown-link .logo-oaktree,
.w-dropdown-link .logo-oakwood {
  margin-top: 14px;
}
